import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { ReactComponent as MTV } from "mtv.svg";
import { ReactComponent as VH1 } from "vh1.svg"
import { animated, useSpring } from "react-spring";
import Menu from "components/Menu";
import themes from "themes.js";
import "./index.scss";
import "./Burger.scss";

const mapStateToProps = state => ({
  theme: themes[state.style.theme]
})
const mapDispatchToProps = null

export default connect(mapStateToProps,mapDispatchToProps)(({ title="", theme }) => {
  const fill = theme.wrapper.fill
  const [menuOpen,setMenuOpen] = useState(false);

  function toggleMenu() {
    setMenuOpen(!menuOpen)
  }

  const [navStyle,setNavStyle] = useSpring(() => theme.wrapper)

  useEffect(() => {
    setNavStyle(theme.wrapper)
  },[theme])

  return (
    <animated.div
      className="Nav"
      style={navStyle}>
      <div className="Nav-home">
        <animated.a
          href="http://www.mtv.com"
          target="_blank"
          rel="noopener noreferrer"
          style={{
            fill: menuOpen ? 'white' : theme.wrapper.fill
          }}>
          <MTV />
        </animated.a>
        <div className="Nav-home-divider" />
        <animated.a
          href="http://www.vh1.com"
          target="_blank"
          rel="noopener noreferrer"
          style={{
            fill: menuOpen ? 'white' : theme.wrapper.fill
          }}>
          <VH1 />
        </animated.a>
        <Link to="/"
          className="Nav-home-local"
          style={{ color: menuOpen ? 'white' : '' }}>
          #SaveOurMoms
        </Link>
      </div>
      <div className="Nav-title">{title}</div>
      <Burger
        fill={fill}
        isOpen={menuOpen}
        onClick={() => {
          toggleMenu()
        }} />
      <Menu isOpen={menuOpen} setOpen={setMenuOpen} />
    </animated.div>
  )
})

const Burger = ({ isOpen, onClick, fill }) => {

  const [bar1,setBar1] = useSpring(() => ({
    transform: "rotate(0deg) translateY(220%)",
    backgroundColor: isOpen ? 'white' : fill
  }))
  const [bar2,setBar2] = useSpring(() => ({
    transform: "rotate(0deg) translateY(-220%)",
    backgroundColor: isOpen ? 'white' : fill
  }))
  const [bar3,setBar3] = useSpring(() => ({
    opacity: 1,
    backgroundColor: isOpen ? 'white' : fill
  }))

  setBar1({
    transform: `rotate(${isOpen ? 45 : 0}deg) translateY(${isOpen ? 0 : 220}%}`,
    backgroundColor: isOpen ? 'white' : fill
  })
  setBar2({
    transform: `rotate(${isOpen ? -45 : 0}deg) translateY(${isOpen ? 0 : -220}%}`,
    backgroundColor: isOpen ? 'white' : fill
  })
  setBar3({
    opacity: isOpen ? 0 : 1,
    backgroundColor: isOpen ? 'white' : fill
  })

  return <button className="Burger" onClick={onClick}>
    <animated.div className="Burger-bar Burger-bar-1" style={bar1} />
    <animated.div className="Burger-bar Burger-bar-2" style={bar3} />
    <animated.div className="Burger-bar Burger-bar-3" style={bar2} />
  </button>
}