export const colors = {
  black: "#000",
  white: "#fff",
  purple: "#9343FB",
  green: "#6DF89F"
}

const mainColor = colors.purple

export default {
  home: {
    wrapper: {
      backgroundColor: colors.white,
      color: colors.black,
      fill: colors.black
    },
    content: {
      backgroundColor: colors.black,
      color: colors.white,
      fill: colors.white,
      highlight: mainColor
    }
  },
  hope: {
    wrapper: {
      backgroundColor: colors.white,
      color: colors.black,
      fill: colors.black
    },
    content: {
      backgroundColor: mainColor,
      color: colors.white,
      fill: colors.white,
      highlight: colors.black
    }
  },
  chat: {
    wrapper: {
      backgroundColor: mainColor,
      color: colors.white,
      fill: colors.white
    },
    content: {
      backgroundColor: colors.white,
      color: colors.white,
      fill: colors.white,
      highlight: mainColor
    }
  }
}