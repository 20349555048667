import React from "react";
import { Link } from "react-router-dom";
import { animated, useSpring } from "react-spring";
import "./index.scss";

export default ({ isOpen, setOpen }) => {
  const [style, set] = useSpring(() => ({
    transform: `translateY(-100%)`,
  }));

  set({
    transform: `translateY(${isOpen ? 0 : -100}%)`,
  });

  function onLinkClick() {
    setOpen(false);
  }

  return (
    <animated.nav className="Menu" style={style}>
      <div className="Menu-inner max font-menu">
        <Link className="Menu-link" to="/" onClick={onLinkClick}>
          Home
        </Link>
        <Link className="Menu-link" to="/about" onClick={onLinkClick}>
          About
        </Link>
        <Link className="Menu-link" to="/chat" onClick={onLinkClick}>
          Chat
        </Link>
        <Link className="Menu-link" to="/resources" onClick={onLinkClick}>
          Resources
        </Link>
        <Link
          className="Menu-link Menu-link--small"
          to="/terms-of-use"
          onClick={onLinkClick}
        >
          Terms of use
        </Link>
        <a
          className="Menu-link Menu-link--small"
          href="https://privacy.paramount.com/policy"
          target="_blank"
          rel="noopener noreferrer"
        >
          Privacy policy
        </a>
        <a
          className="Menu-link Menu-link--small"
          href="https://privacy.paramount.com/en/policy#additional-information-us-states"
          target="_blank"
          rel="noopener noreferrer"
        >
          California Notice
        </a>
        <a
          className="Menu-link Menu-link--small ot-sdk-show-settings"
          rel="noopener noreferrer"
        >
          Your Privacy Choices
        </a>
      </div>
    </animated.nav>
  );
};
