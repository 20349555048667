const defaultState = {
  theme: 'home'
}

export default (state=defaultState, action) => {
  switch (action.type) {
    case "SET_THEME":
      return {
        ...state,
        theme: action.value
      }

    default:
      return state
  }
}