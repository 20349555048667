import React from "react";
import './Message.scss';
import SlideIn from "components/SlideIn";
import { ReactComponent as Tail } from "bubble_tail.svg";
import pregnancy from "pregnancy.jpg";

export default ({ sender, text, isFirstPerson, link, offset }) => {
  return (
    <SlideIn offset={{
        top: 0,
        bottom: 100
      }}>
      <div className={`Message ${isFirstPerson ? 'Message--user' : 'Message--sender'}`}>

        <div className="Message-inner">
          <Tail />
          <div className="Message-sender">{sender}</div>
          <div className="Message-body" dangerouslySetInnerHTML={{ __html: text }} />

        </div>
        {link && (
          <a className="Message-link" href={link} target="_blank" rel="noopener noreferrer">
            <img src={pregnancy} alt="" />
            <div className="Message-link-text">Body changes and discomfort | womenshealth.gov</div>
          </a>
        )}
      </div>
    </SlideIn>
  )
}