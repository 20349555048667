import React, { useEffect } from "react";
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import Message from "./Message.js";
import Frame from "components/Frame";
import data from "chat.json";
import themes from "themes.js";
import "./index.scss";
import ScrollToTopOnMount from 'components/ScrollToTopOnMount';
import { setTheme } from "redux/actions"
import SlideIn from "components/SlideIn"
import ScrollDown from 'components/ScrollDown';

const mapStateToProps = state => ({
  theme: themes[state.style.theme]
})
const mapDispatchToProps = dispatch => ({
  setTheme: value => {
    dispatch(setTheme(value))
  }
})
export default connect(mapStateToProps,mapDispatchToProps)(({ theme, setTheme }) => {
  useEffect(() => {
    setTheme('chat')
  },[])
  return <Chat messages={data.messages} theme={theme} />
})

const Chat = ({ title, messages, theme }) => (
  <Frame className="Chat" style={theme.content}>
    <ScrollToTopOnMount />
    <div className="Chat-heading">Being a mama’s best ally can be as easy as starting a group chat to support her.</div>
    {messages.map(message => <Message key={message.id} {...message} />)}
    <SlideIn>
      <div className="Home-cta">
        <Link className="Home-cta-link Home-cta-link--primary" to="/resources">
          <div className="Home-cta-link-heading">Support the moms in your life</div>
          <div className="Home-cta-link-subheading">Tactical resources and things you can do</div>
        </Link>
      </div>
    </SlideIn>
    <ScrollDown fill={'black'} />
  </Frame>
)