import React, { useEffect, useState } from "react"
import { animateScroll as scroll } from 'react-scroll'

export default({ fill }) => {
  const [show,setShow] = useState(true)
  useEffect(() => {
    window.addEventListener("scroll",onScroll)
    onScroll()
    return () => {
      window.removeEventListener("scroll",onScroll)
    }
  },[])
  function onScroll() {
    const newShow = window.scrollY + window.innerHeight < document.body.scrollHeight
    setShow(newShow)
  }
  return (
    show && <button
      className="Frame-scroll"
      onClick={() => {
        scroll.scrollMore(window.innerHeight - 58)
      }}
      >
      <div className="Frame-scroll-bar" style={{
        backgroundColor: fill
      }} />
      <div className="Frame-scroll-bar" style={{
        backgroundColor: fill
      }} />
    </button>
  )
}