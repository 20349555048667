import React, { useEffect } from "react";
import { animated, useSpring } from "react-spring";

const Frame = ({
  className="",
  style,
  children
}) => {

  const [animatedStyle,set] = useSpring(() => ({
    //transform: "scale(0.5)",
    opacity: 0,
    ...style,
    width: '100%',
    height: '100%',
    config: {
      clamp: true,
      precision: .2
    }
  }))

  useEffect(() => {
    set({
      //transform: "scale(1)",
      ...style,
      opacity: 1,
    })
  }, [style])

//   const [rect,setRect] = useState()
//   useEffect(() => {
//     window.addEventListener("resize",onResize);
//     onResize()
//     return () => {
//       window.removeEventListener("resize",onResize)
//     }
//   },[])
//
//   const ref = useRef()
//   function onResize() {
//     setRect(ref.current.getBoundingClientRect())
//   }

  return (
    <animated.article
      // ref={ref}
      style={style}
      className={`${className} frame`}>
      <animated.div
        style={animatedStyle}
        className="frame-bar">
        <div className="frame-inner">
          {children}
        </div>
      </animated.div>
    </animated.article>
  )
}

export default Frame;