import React from "react";
import { Waypoint } from "react-waypoint";
import { animated, useSpring, config } from "react-spring";

export default ({ children }) => {

  const [style,set] = useSpring(() => ({
    opacity: 0,
    transform: `scale(0.8)`,
    config: config.slow
  }))

  function enter() {
    set({
      opacity: 1,
      transform: `scale(1)`,
      delay: 100
    })
  }

  function leave() {
    set({
      opacity: 0,
      transform: `scale(0.8)`,
      delay: 100
    })
  }

  return (
    <Waypoint
      onEnter={() => {
        enter()
      }}
      onLeave={() => {
        leave()
      }}
      bottomOffset={150}
     >
      <animated.div style={style}>{children}</animated.div>
    </Waypoint>
  )
}