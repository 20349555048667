import React, { useRef, useEffect, useState } from "react";

export default ({ triggerHook=0.5, onUp, onDown, children }) => {
  const ref = useRef()
  //const scrollY = useRef(window.scrollY)
  //const [scrollDirection,setScrollDirection] = useState()
  const [location,setLocation] = useState()
  useEffect(() => {
    window.addEventListener("scroll",onScroll)
    onScroll()

    return () => {
      window.removeEventListener("scroll",onScroll)
    }
  },[])

  useEffect(()=> {
    switch (location) {
      case 'BELOW':
        onDown();
        break;
      case 'ABOVE':
        onUp();
        break;
      default:
        console.warn()
    }
  },[location])

  function onScroll() {
    //ScrollDirection
    // if (window.scrollY > scrollY.current) {
    //   setScrollDirection("DOWN")
    // } else if (window.scrollY < scrollY.current) {
    //   setScrollDirection("UP")
    // }
    // scrollY.current = window.scrollY

    //Element Position
    if (ref.current) {
      const rect = ref.current.getBoundingClientRect()
      const triggerSpot = window.innerHeight*triggerHook;
      if (rect.top <= triggerSpot) {
        setLocation('BELOW')
      } else {
        setLocation('ABOVE')
      }
    }
  }

  return <div ref={ref}>{children}</div>
}