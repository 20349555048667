import React, { useEffect } from "react";
import { connect } from 'react-redux';
import Frame from "components/Frame";
import themes from "themes.js";
import "./index.scss";
import ScrollToTopOnMount from 'components/ScrollToTopOnMount';
import { setTheme } from "redux/actions";
import { ReactComponent as Tail } from "bubble_tail.svg";
import SlideIn from "components/SlideIn";
import Share from "components/Share";

const mapStateToProps = state => ({
  theme: themes[state.style.theme]
})
const mapDispatchToProps = dispatch => ({
  setTheme: value => {
    dispatch(setTheme(value))
  }
})

export default connect(mapStateToProps,mapDispatchToProps)(({
  title,
  slug,
  blocks,
  theme,
  setTheme
}) => {

  useEffect(() => {
    setTheme('hope')
  },[])

  return <Frame className="Page" style={theme.content}>
    <ScrollToTopOnMount />
    {/* <h1 className="quote-title" style={{ */}
    {/*   fill: theme.content.fill */}
    {/* }}> */}
    {/*   <Tail /> */}
    {/*   {title} */}
    {/* </h1> */}
    {blocks.map(block => {
      switch (block.type) {
        case 'text':
          return !block.noAnimation ? <SlideIn key={block.id}>
            <div dangerouslySetInnerHTML={{ __html: block.text }} />
          </SlideIn> : <div dangerouslySetInnerHTML={{ __html: block.text }} />

        case 'heading':
          return !block.noAnimation ? <SlideIn key={block.id}>
            <h2 className="quote-title"><Tail />{block.text}</h2>
          </SlideIn> : <h2 className="quote-title"><Tail />{block.text}</h2>

        default:
          console.log('block not set up: ' + block.type)
          return null
      }
    })}
    <Share />
  </Frame>
})