import React from "react";
import {
  FacebookShareButton,
  TwitterShareButton,
} from 'react-share';
import './share.scss';

import { ReactComponent as Twitter } from "./social_twitter.svg";
import { ReactComponent as Facebook } from "./social_facebook.svg";

const TEXT = "Every day 2-3 women in the US die from pregnancy-related complications. Over half are preventable. Learn how we all  have the power to change this story at"

export default ({ text=TEXT, url="http://saveourmoms.com" }) => {
  return <div className="Share">
    <div className="Share-buttons">
      <FacebookShareButton quote={text} url={url}>
        <Facebook />
      </FacebookShareButton>
      <TwitterShareButton title={text} hashtags={["SaveOurMoms"]} url={url}>
        <Twitter />
      </TwitterShareButton>
    </div>
    <div className="Share-cta">Share</div>
  </div>
}